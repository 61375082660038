<template>
  <div class="page-wrap">
    <div ref="map" class="map-wrap"></div>
    <div class="weather-time-wrap">
      <div class="time" v-text="nowTime"></div>
      <div class="weather">
        <div class="text" v-text="weatherData.weather"></div>
        <div class="degree" v-text="weatherData.temperature"></div>
      </div>
      <div class="park-type-wrap">
        <div v-for="(item, index) in parkTypeList" :key="index">
          <el-popover
            placement="left-start"
            width="180"
            trigger="click"
            :offset="150"
            v-if="item.parkType === '5'"
          >
            <div class="hospital-type-wrap">
              <div class="no-data" v-if="hospitalTypeList.length <= 0">
                暂无分类
              </div>
              <div v-else>
                <div
                  @click="handleParkTypeClick(item.parkType, Iitem.useType)"
                  class="hospital-type"
                  :class="Iitem.useType === hospitalType ? 'active' : ''"
                  v-for="(Iitem, Iindex) in hospitalTypeList"
                  v-text="Iitem.useTypeDesc"
                  :key="Iindex"
                ></div>
              </div>
            </div>
            <div slot="reference" class="item">
              <div class="des" v-text="item.parkTypeDesc"></div>
              <img class="icon" :src="item.iconUrl" />
            </div>
          </el-popover>
          <div v-else class="item" @click="handleParkTypeClick(item.parkType)">
            <div
              class="des"
              :style="{ color: item.markColor }"
              v-text="item.parkTypeDesc"
            ></div>
            <img class="icon" :src="item.iconUrl" />
          </div>
        </div>
        <el-popover
          v-if="selectedParkType === '5'"
          placement="left"
          width="400"
          trigger="click"
        >
          <div class="map-mark-search-wrap">
            <div class="search-title">
              请输入您预定开业的地址，平台将为您评估您选择的地址是否符合开业条件：
            </div>
            <div class="search-input-wrap">
              <el-input
                class="search-input"
                prefix-icon="el-icon-search"
                v-model="addrSearch"
                placeholder="例如：上海市杨浦区1008号"
                clearable
              ></el-input>
              <el-button @click="handleSelfMarkAdd" type="primary"
                >确认</el-button
              >
            </div>
          </div>
          <div slot="reference" class="item map-mark">
            <div class="des">地图打点选址</div>
            <div class="round"></div>
          </div>
        </el-popover>
      </div>
    </div>
    <div v-show="!filterShow" class="btn-filter" @click="handleFilterShow">
      搜索条件
    </div>
    <div class="filter-wrap" v-show="filterShow">
      <div class="mask" @click="filterShow = false"></div>
      <transition name="move-l-r">
        <div class="filter-content" v-show="filterShow">
          <i class="el-icon-close" @click="filterShow = false"></i>
          <div class="item" v-for="(item, index) in filterList" :key="index">
            <div class="title" v-text="item.parentFilter.name"></div>
            <div class="condition">
              <el-checkbox-group v-model="filterFormTempList">
                <el-checkbox
                  :key="childItem.id"
                  v-for="childItem in item.childFilter"
                  :label="childItem.id"
                  >{{ childItem.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </div>
          <div class="btn-wrap">
            <a class="btn btn-sure" @click="handleFilterSure">搜 索</a>
            <a class="btn btn-cancel" @click="handleFilterReset">重 置</a>
          </div>
        </div>
      </transition>
    </div>
    <div class="street-list-wrap" v-if="streetList.length > 0">
      <div class="title">办公楼宇信息</div>
      <div class="item-title-wrap">
        <div class="item-title">名称</div>
        <div class="item-title">地址</div>
        <div class="item-title">待租面积</div>
        <div class="item-title">租金</div>
        <div class="item-title">物业费</div>
        <div class="item-title">停车价格</div>
        <div class="item-title">联系人</div>
        <div class="item-title">联系电话</div>
        <div class="item-title">备注</div>
      </div>
      <vue-seamless-scroll
        :data="streetList"
        class="street-scroll"
        :class-option="scrollClassOption"
      >
        <ul class="scroll-list">
          <li
            class="scroll-item"
            v-for="(item, index) in streetList"
            :key="index"
          >
            <div class="item" v-text="item.buildingName"></div>
            <div class="item" v-text="item.address"></div>
            <div class="item" v-text="item.usableArea"></div>
            <div class="item" v-text="item.rentalPrice"></div>
            <div class="item" v-text="item.managerPrice"></div>
            <div class="item" v-text="item.parkingPrice"></div>
            <div class="item" v-text="item.contacts"></div>
            <div class="item" v-text="item.telNo"></div>
            <div class="item" v-text="item.comment"></div>
          </li>
        </ul>
      </vue-seamless-scroll>
    </div>
    <div class="m-dialog dxs-czt" v-show="dialogDxsCztVisible">
      <div class="dia-content-wrap" ref="diaDxsCtz">
        <img class="ding" src="../assets/images/czt-ding.png" />
        <img class="title1" src="../assets/images/czt-biaoti.png" />
        <div class="title2">
          <div class="row1">以“育苗”为关键，</div>
          <div class="row2">打磨发展“新引擎”</div>
        </div>
        <div class="con-wrap">
          <div class="con">
            <div class="text">
              高校大学生创业“成长田”依托杨浦“百年大学”的深厚底蕴，针对高校大学生创业初期资金紧张、“试水”创业等特点，由区市场监管局联合高校、大学科技园，根据区内高校优势特色专业及高校大学生创业意愿，选取大学科技园内的部分功能区作为创业者经营场所，为创业者提供低门槛、零成本的办公场所及初期综合指导服务，并提供登记注册、知识产权转化指导、年报申报指导、“小个专”党建咨询指导等配套市场监管服务。同时，后续将根据创业者的不同阶段的发展需求，支持创业者变更经营者或转型登记为企业，为大学生提供充分成长、发展、转型空间。
            </div>
            <div class="tian-wrap">
              <div class="tian tian1" @click="handleGoCytDetail('432')">
                <img class="shu" src="../assets/images/czt-mp.png" />
                <div class="text-wrap">
                  <div>同济科技园</div>
                  <div>智空间</div>
                </div>
                <img class="cw" src="../assets/images/czt-cw.png" />
              </div>
              <div class="tian tian2" @click="handleGoCytDetail('433')">
                <img class="shu" src="../assets/images/czt-mp.png" />
                <div class="text-wrap">
                  <div>上海体育国家</div>
                  <div>大学科技园</div>
                </div>
                <img class="cw" src="../assets/images/czt-cw.png" />
              </div>
            </div>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogDxsCztVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog" v-show="dialogVisible">
      <div class="dia-content-wrap" ref="dia">
        <div class="main-wrap">
          <div
            class="gz-wrap"
            v-if="parkDetail.pcParkMasterInfoVO.mobileMpPath"
          >
            <img
              class="gz-code"
              :src="parkDetail.pcParkMasterInfoVO.mobileMpPath"
              alt="code"
            />
            <span class="text">微信扫一扫关注公众号</span>
          </div>
          <div class="text-wrap">
            <img
              class="logo"
              :src="parkDetail.pcParkMasterInfoVO.logoPath"
              alt="logo"
              v-if="parkDetail.pcParkMasterInfoVO.logoPath"
            />
            <div
              class="logo-text"
              v-else
              v-text="parkDetail.pcParkMasterInfoVO.parkName"
            ></div>
            <div class="item-wrap">
              <div class="item">
                <i class="fa fa-map-marker" />
                <div class="text">
                  地址：{{ parkDetail.pcParkMasterInfoVO.address }}
                </div>
                <div @click="handleGoHere('driving')" class="go-here">
                  导航
                  <i class="fa fa-sign-in"></i>
                </div>
              </div>
              <div class="item">
                <i class="fa fa-phone" />
                <div class="text">
                  电话：{{ parkDetail.pcParkMasterInfoVO.telNo }}
                </div>
              </div>
            </div>
            <div class="item-wrap">
              <div class="item">
                <i class="fa fa-paperclip" />
                <div class="text">
                  邮编：{{ parkDetail.pcParkMasterInfoVO.postCode }}
                </div>
              </div>
              <div class="item">
                <i class="fa fa-envelope-open" />
                <div class="text">
                  邮箱：{{ parkDetail.pcParkMasterInfoVO.email }}
                </div>
                <div @click="handleInfoCopy" class="go-here">
                  信息复制
                  <i class="fa fa-book"></i>
                </div>
              </div>
            </div>
          </div>
          <div class="code-wrap">
            <div class="code"></div>
            <canvas
              width="105"
              height="105"
              class="canvas"
              ref="canvasCode"
            ></canvas>
            <span class="text">微信扫一扫查看详情</span>
          </div>
        </div>
        <div class="dia-filter-wrap">
          <div class="row">
            <div class="item">
              <div class="tit">街道：</div>
              <div
                class="con"
                v-text="parkDetail.pcParkMasterInfoVO.areaName"
              ></div>
            </div>
            <div class="item">
              <div class="tit">类型：</div>
              <div
                class="con"
                v-text="
                  selectListToMap(userTypeList)[
                    parkDetail.pcParkMasterInfoVO.useType
                  ]
                "
              ></div>
            </div>
          </div>
          <div class="row">
            <div class="item">
              <div class="tit">租金：</div>
              <div class="con">
                {{
                  `${
                    parkDetail.pcParkMasterInfoVO.rentalPrice
                      ? parkDetail.pcParkMasterInfoVO.rentalPrice
                      : ""
                  }${
                    parkDetail.pcParkMasterInfoVO.rentalPriceUnit
                      ? parkDetail.pcParkMasterInfoVO.rentalPriceUnit
                      : ""
                  }`
                }}{{
                  parkDetail.pcParkMasterInfoVO.managerPrice
                    ? `（物业费：${parkDetail.pcParkMasterInfoVO.managerPrice}${parkDetail.pcParkMasterInfoVO.managerPriceUnit}）`
                    : ``
                }}
              </div>
            </div>
            <div class="item">
              <div class="tit">面积：</div>
              <div class="con">
                {{ parkDetail.pcParkMasterInfoVO.totalArea }}㎡
              </div>
            </div>
          </div>
        </div>
        <div class="main-img-wrap">
          <div class="item dzdt">
            <div class="title">周边配套</div>
            <div
              class="img"
              :style="{
                background: `url(${parkDetailExtra.eMapImg}) no-repeat center
                center`,
              }"
              @click="handleImgView(parkDetailExtra.eMapImg)"
            ></div>
          </div>
          <div class="item cyl">
            <div class="title">
              产业链
              <div
                class="leida-wrap"
                v-if="parkDetailExtra.parkRadarDataList.length > 0"
                @click="handleLeiDaTuShow"
              >
                <img
                  class="img-point"
                  src="../assets/images/point.png"
                  alt="选择"
                />
                产业布局图
                <img
                  class="img-leida"
                  src="../assets/images/leida.png"
                  alt="雷达图"
                />
              </div>
            </div>
            <div class="img-wrap">
              <div
                class="img-cly"
                v-for="item in parkDetailExtra.inchImg"
                :key="item.imgId"
                :style="{
                  background: `url(${item.imgPath}) no-repeat center
                center`,
                }"
                @click="handleQiYeLogo(item)"
              ></div>
            </div>
          </div>
        </div>
        <div class="main-lunbo-fy-wrap">
          <div class="item kzfy">
            <div class="title">空置房源统计</div>
            <div class="table">
              <el-table
                :data="parkDetailExtra.roomTableData"
                style="width: 100%"
                height="300"
                border
                show-summary
                :summary-method="getSummaries"
                :span-method="tableSpanMethod"
                @row-click="handleRoomRowClick"
              >
                <div slot="empty" class="empty-text">
                  园区暂无待租空置房源，点击屏幕左侧，搜索一下发现更多优质园区吧！
                </div>
                <el-table-column
                  label="区域"
                  prop="baseBuildName"
                ></el-table-column>
                <el-table-column label="室号" prop="roomNo"></el-table-column>
                <el-table-column label="租金">
                  <template slot-scope="scope">
                    <div>
                      {{ scope.row.rentalPrice }}{{ scope.row.rentalPriceUnit }}
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="可用面积" prop="usableArea">
                  <template slot-scope="scope">
                    <div>{{ scope.row.usableArea }}㎡</div>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="item ypyl">
            <div class="title">园区一览</div>
            <div class="carousel">
              <swiper :options="swiperOption">
                <swiper-slide
                  v-for="(item, index) in parkDetailExtra.scrollImg"
                  :key="index"
                >
                  <div
                    class="img"
                    :style="{
                      background: `url(${item}) no-repeat center
                center`,
                    }"
                    @click="handleImgView(item)"
                  ></div>
                </swiper-slide>
                <div class="swiper-pagination" slot="pagination"></div>
                <div class="swiper-button-prev1" slot="button-prev"></div>
                <div class="swiper-button-next2" slot="button-next"></div>
              </swiper>
            </div>
          </div>
        </div>
        <div class="label-wrap">
          <div class="title">园区特色</div>
          <div
            class="label"
            :class="{ 'tag-link': item.labelName.indexOf('<a') == 0 }"
            v-for="(item, index) in parkDetail.pcParkLabelInfoVO"
            :key="index"
            v-html="item.labelName"
          ></div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog bmd" v-show="dialogBmdVisible">
      <div class="dia-content-wrap" ref="diaBmd">
        <div
          class="title"
          v-text="parkDetail.pcParkMasterInfoVO.parkName"
        ></div>
        <div class="con-wrap">
          <div class="item">
            <i class="fa fa-map-marker" />
            <div class="text">
              地址：{{ parkDetail.pcParkMasterInfoVO.address }}
              <div @click="handleGoHere('driving')" class="go-here">
                导航
                <i class="fa fa-sign-in"></i>
              </div>
            </div>
          </div>
          <div class="item">
            <i class="fa fa-phone" />
            <div class="text">
              电话：{{ parkDetail.pcParkMasterInfoVO.telNo }}
            </div>
          </div>
        </div>
        <div class="con-wrap" v-if="parkDetail.pcParkLabelInfoVO.length > 0">
          <div class="item">
            <i class="fa fa-tag" />
            <div class="text">
              标签：
              <div
                class="tag"
                v-for="(item, index) in parkDetail.pcParkLabelInfoVO"
                :key="index"
                v-text="item.labelName"
              ></div>
            </div>
          </div>
        </div>
        <el-row
          v-if="parkDetail.pcParkPropertiesInfoVO.length > 0"
          :gutter="20"
          class="flex-wrap"
          type="flex"
          align="top"
        >
          <el-col
            class="m-col"
            v-for="(item, index) in parkDetail.pcParkPropertiesInfoVO"
            :key="index"
            :span="12"
          >
            <div class="p-info-title">
              <i class="fa fa-file-text"></i>
              <span v-text="item.parkProType + '：'"></span>
            </div>
            <div class="p-info-con" v-text="item.parkProContext"></div>
          </el-col>
        </el-row>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogBmdVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog map-navigation" v-show="dialogMapNavigationVisible">
      <div class="dia-content-wrap" ref="diaMapNavigation">
        <div class="map-nav-wrap">
          <div ref="navMap" class="map-nav"></div>
          <div class="map-nav-result-wrap">
            <div class="nav-type-wrap">
              <div
                class="type"
                @click="handleGoHere('driving')"
                :class="mapNavType === 'driving' ? 'active' : ''"
              >
                驾车
              </div>
              <div
                class="type"
                @click="handleGoHere('transfer')"
                :class="mapNavType === 'transfer' ? 'active' : ''"
              >
                公交
              </div>
              <div
                class="type"
                @click="handleGoHere('riding')"
                :class="mapNavType === 'riding' ? 'active' : ''"
              >
                骑行
              </div>
              <div
                class="type"
                @click="handleGoHere('walking')"
                :class="mapNavType === 'walking' ? 'active' : ''"
              >
                步行
              </div>
            </div>
            <div id="map_navigation" class="nav-map-panel"></div>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogMapNavigationVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog img-view" v-show="dialogImgVisible">
      <div class="dia-content-wrap" ref="diaImg">
        <img :src="viewImgUrl" alt="图片" ref="viewImg" />
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogImgVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog qi-ye-info" v-show="dialogQiYeInfoVisible">
      <div class="dia-content-wrap" ref="diaQiYeInfo">
        <div class="info-wrap">
          <div class="name" v-text="qiYeCompanyInfo.companyName"></div>
          <div class="info">
            <div
              class="logo-img"
              :style="{
                background: `url(${qiYeCompanyInfo.imgPath}) no-repeat center
                center`,
              }"
            ></div>
            <div class="des" v-text="qiYeCompanyInfo.companyDesc"></div>
          </div>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogQiYeInfoVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog lei-da" v-show="dialogLeiDaVisible">
      <div class="dia-content-wrap" ref="diaLeiDa">
        <div class="title">园区产业布局图</div>
        <div class="chart" ref="leiDaChart"></div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogLeiDaVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
    <div class="m-dialog lou-yu" v-show="dialogLouYuVisible">
      <div class="dia-content-wrap" ref="diaLouYu">
        <div class="title">{{ areaItem.streetName + "楼宇信息" }}</div>
        <div class="table-wrap">
          <el-table
            :data="areaItem.parkBuildingList"
            max-height="660px"
            size="medium"
          >
            <el-table-column prop="buildingName" label="名称" align="center">
            </el-table-column>
            <el-table-column prop="address" label="地址" align="center">
            </el-table-column>
            <el-table-column prop="usableArea" label="待租面积" align="center">
            </el-table-column>
            <el-table-column prop="rentalPrice" label="租金" align="center">
            </el-table-column>
            <el-table-column prop="managerPrice" label="物业费" align="center">
            </el-table-column>
            <el-table-column
              prop="parkingPrice"
              label="停车价格"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="contacts" label="联系人" align="center">
            </el-table-column>
            <el-table-column prop="telNo" label="联系电话" align="center">
            </el-table-column>
            <el-table-column prop="comment" label="备注" align="center">
            </el-table-column>
          </el-table>
        </div>
        <div class="btn-wrap">
          <el-button type="primary" @click="dialogLouYuVisible = false"
            >关 闭</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import vueSeamlessScroll from "vue-seamless-scroll";
import jsonp from "jsonp";
import qrcode from "qrcode";
const mark_park = require("@/assets/images/mark-park.png");
const mark_gray = require("@/assets/images/mark-gray.png");
import {
  DICT_T_PARK_USE_TYPE,
  YPQ_CENTER_POSITION,
  YPQ_QZH_POSITION,
  WEATHER_BAIDU_URL,
  NOTIFY_STAY_TIME,
  STATUS_CODE_OK,
} from "@/config";
import {
  filterParkGet,
  parkDetailGet,
  filterGet,
  inchCompanyCardGet,
  streetListGet,
  gpsInfoGetByAddress,
  initParamsGet,
  parkTypeListGet,
} from "@/api/home";
import { getSelectList } from "@/api/base";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { imgWidthHeightGet, jsonDeepClone, selectListToMap } from "@/utils";
export default {
  name: "Index",
  data() {
    return {
      selectListToMap,
      time: null,
      nowTime: "",
      userTypeList: [],
      filterShow: false,
      filterList: [],
      filterFormList: [],
      filterFormTempList: [],
      swiperOption: {
        autoplay: true,
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
        effect: "coverflow",
        grabCursor: true,
        watchSlidesProgress: true,
        centeredSlides: true,
        loopedSlides: 3,
        slidesPerView: 3,
        coverflowEffect: {
          rotate: 30,
          stretch: 0,
          depth: 60,
          modifier: 2,
          slideShadows: false,
        },
      },
      parkTypeList: [], // 网点类型列表
      parkList: [], // 园区列表
      businessAreaList: [], // 商圈列表
      hospitalTypeList: [], // 医疗机构分类列表
      selectedParkType: "",
      hospitalType: "", // 选择的医疗机构分类列表
      addrSearch: "", // 地图打点地址搜索内容
      parkDetail: {
        pcParkBuildRentalInfoVO: [],
        pcParkImgInfoVO: [],
        pcParkLabelInfoVO: [],
        pcParkMasterInfoVO: {},
        pcParkPropertiesInfoVO: [],
        tables: [],
      }, // 园区详情
      parkDetailExtra: {
        eMapImg: "", // 电子地图
        inchImg: [], // 产业链
        scrollImg: [], // 轮播图
        roomTableData: [], // 待租房数组
        parkRadarDataList: [], // 雷达图数据
      },
      dialogVisible: false,
      weatherData: {},
      dialogBmdVisible: false,
      viewImgUrl: "",
      dialogImgVisible: false,
      dialogMapNavigationVisible: false, // 地图导航弹窗
      mapNavType: "driving", // 导航类型选择
      dialogLeiDaVisible: false, // 五力图显示
      dialogQiYeInfoVisible: false, // 企业简介弹窗显示
      qiYeCompanyInfo: {}, // 企业信息
      streetList: [],
      scrollClassOption: {
        step: 0.3,
        hoverStop: true,
        limitMoveNum: 2,
      },
      dialogLouYuVisible: false, // 楼宇信息弹窗
      dialogDxsCztVisible: false, // 大学生成长田信息弹窗
      areaList: [], // 区域信息列表
      areaItem: {}, // 区域信息
    };
  },
  computed: {
    tableCountMap() {
      let countMap = {};
      this.parkDetail.pcParkBuildRentalInfoVO.forEach((item) => {
        if (item.baseBuildName !== "合计") {
          countMap[item.baseBuildName] = item.roomRentalInfoVOList.length;
        }
      });
      return countMap;
    },
    renderParkList() {
      if (this.selectedParkType) {
        let parkList = this.parkList.filter(
          (item) => item.parkType === this.selectedParkType
        );
        if (this.hospitalType) {
          parkList = parkList.filter(
            (item) => item.useType === this.hospitalType
          );
        }
        return parkList;
      } else {
        return this.parkList;
      }
    },
  },
  created() {
    this.handleTimeInit();
    this.handleWeatherGet();
    this.handleFilterListGet();
    this.handleUserTypeList();
    this.handleParkTypeListGet();
  },
  mounted() {
    this.handleStreetListGet();
    this.handleInit();
  },
  methods: {
    // 网点类型列表获取
    async handleParkTypeListGet() {
      let { data } = await parkTypeListGet();
      if (data.code === STATUS_CODE_OK) {
        this.parkTypeList = data.data;
        this.parkTypeList.push({
          iconUrl: require("@/assets/images/mark-all.png"),
          markColor: "#ffffff",
          orderIndex: 10000,
          parkType: "",
          parkTypeDesc: "所有分类",
        });
      }
    },
    // 获取楼宇列表
    async handleStreetListGet() {
      let { data } = await streetListGet();
      if (data.code === STATUS_CODE_OK) {
        this.areaList = data.data;
        let list = [];
        data.data.forEach((item) => {
          list = list.concat(item.parkBuildingList);
        });
        this.streetList = list;
      }
    },
    // 获得天气信息
    handleWeatherGet() {
      const that = this;
      jsonp(WEATHER_BAIDU_URL, {}, (err, res) => {
        let { status, results } = res;
        if (status === "success") {
          that.weatherData = results[0].weather_data[0];
        }
      });
    },
    // 初始化时间
    handleTimeInit() {
      this.time = window.setInterval(() => {
        this.nowTime = this.$moment().format("YYYY年MM月DD日 HH:mm:ss");
      }, 1000);
    },
    // 获取使用类型
    async handleUserTypeList() {
      let { data } = await getSelectList({
        dictTable: DICT_T_PARK_USE_TYPE,
      });
      if (data.code === STATUS_CODE_OK) {
        this.userTypeList = data.data;
      }
    },
    // 获取左侧过滤条件
    async handleFilterListGet() {
      let { data } = await filterGet();
      if (data.code === STATUS_CODE_OK) {
        this.filterList = data.data;
      }
    },
    // 获取园区列表数据
    async handleParkGet() {
      let { data } = await filterParkGet({
        filterIds: this.filterFormTempList.join(),
      });
      if (data.code === STATUS_CODE_OK) {
        this.parkList = data.data.parkInfo;
        this.businessAreaList = data.data.businessAreaInfo;
        this.handleHospitalTypeList();
        const initParams = await initParamsGet();
        if (initParams.data.code === STATUS_CODE_OK) {
          this.handleParkTypeClick(initParams.data.data.PARK_TYPE_STR);
        }
      }
    },
    // 民营医院分类获取
    handleHospitalTypeList() {
      this.hospitalTypeList = this.parkList.filter(
        (item) => item.parkType === "5" && item.useTypeDesc
      );
      this.hospitalTypeList = this.hospitalTypeList.filter(
        (item, index, arr) => {
          return (
            arr.findIndex((t) => t.useTypeDesc === item.useTypeDesc) === index
          );
        }
      );
    },
    // 获取园区详情数据
    async handleParkDetailGet({ id, parkType }) {
      const that = this;
      let { data } = await parkDetailGet({
        parkId: id,
      });
      if (data.code === STATUS_CODE_OK) {
        that.parkDetail = data.data;
        let dia = null;
        if (parkType === "1") {
          that.dialogVisible = true;
          that.handleParkDetailExtraGet();
          dia = that.$refs.dia;
        } else {
          that.dialogBmdVisible = true;
          dia = that.$refs.diaBmd;
        }
        that.$nextTick(() => {
          const { innerHeight, innerWidth } = window;
          const { offsetHeight, offsetWidth } = dia;
          dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
          dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
          if (parkType === "1") {
            qrcode.toCanvas(
              that.$refs.canvasCode,
              `${process.env.VUE_APP_H5_PROXY_URL}/#/detail?parkId=${that.parkDetail.pcParkMasterInfoVO.id}`,
              {
                margin: 0,
                width: 105,
              }
            );
          }
        });
      }
    },
    // 园区详细信息额外信息数据整理
    handleParkDetailExtraGet() {
      const that = this;
      that.parkDetailExtra = {
        eMapImg: "",
        inchImg: [],
        scrollImg: [],
        roomTableData: [],
        parkRadarDataList: [],
      };
      that.parkDetail.pcParkImgInfoVO.forEach((item) => {
        switch (item.imgType) {
          case "EMAP":
            that.parkDetailExtra.eMapImg = item.imgPath;
            break;
          case "INCH":
            that.parkDetailExtra.inchImg.push(item);
            break;
          case "SCROLL":
            that.parkDetailExtra.scrollImg.push(item.imgPath);
            break;
          default:
            that.parkDetailExtra.scrollImg.push(item.imgPath);
        }
      });
      that.parkDetail.pcParkPropertiesInfoVO.forEach((item) => {
        switch (item.parkProCategory) {
          case "PARK_RADAR_CHART":
            that.parkDetailExtra.parkRadarDataList.push(item);
            break;
        }
      });
      for (
        let i = 0, len = that.parkDetail.pcParkBuildRentalInfoVO.length;
        i < len;
        i++
      ) {
        let mainItem = that.parkDetail.pcParkBuildRentalInfoVO[i];
        if (mainItem.baseBuildName === "合计") {
          continue;
        }
        mainItem.roomRentalInfoVOList.forEach((item) => {
          that.parkDetailExtra.roomTableData.push(item);
        });
      }
    },
    // 初始化
    handleInit() {
      const that = this;
      that.map = new window.AMap.Map(that.$refs.map, {
        mapStyle: "amap://styles/normal",
        center: YPQ_CENTER_POSITION,
        zoom: 14,
      });
      that.navMap = new window.AMap.Map(that.$refs.navMap, {
        center: YPQ_CENTER_POSITION,
        zoom: 13,
      });
      that.map.plugin("AMap.ToolBar", () => {
        const toolBar = new window.AMap.ToolBar({
          position: "LB",
          liteStyle: true,
        });
        that.map.addControl(toolBar);
      });
      that.map.plugin("AMap.Scale", () => {
        const scale = new window.AMap.Scale({
          position: "LT",
          offset: new window.AMap.Pixel(10, 10),
        });
        that.map.addControl(scale);
      });
      // 创建行政区查询对象
      that.district = new window.AMap.DistrictSearch({
        // 返回行政区边界坐标等具体信息
        extensions: "all",
        // 设置查询行政区级别为 区
        level: "district",
      });
      that.navMap.plugin("AMap.ToolBar", () => {
        const toolBar = new window.AMap.ToolBar({
          position: "RB",
          liteStyle: true,
        });
        that.navMap.addControl(toolBar);
      });
      that.navMap.plugin("AMap.Scale", () => {
        const scale = new window.AMap.Scale({
          position: "LT",
          offset: new window.AMap.Pixel(10, 10),
        });
        that.navMap.addControl(scale);
      });
      that.navMap.plugin("AMap.Driving", () => {
        that.driving = new window.AMap.Driving({
          map: that.navMap,
          panel: "map_navigation",
        });
      });
      that.navMap.plugin("AMap.Transfer", () => {
        that.transfer = new window.AMap.Transfer({
          map: that.navMap,
          panel: "map_navigation",
        });
      });
      that.navMap.plugin("AMap.Riding", () => {
        that.riding = new window.AMap.Riding({
          map: that.navMap,
          panel: "map_navigation",
        });
      });
      that.navMap.plugin("AMap.Walking", () => {
        that.walking = new window.AMap.Walking({
          map: that.navMap,
          panel: "map_navigation",
        });
      });
      that.handleParkGet();
    },
    // 初始化杨浦区
    handleYPQMap() {
      const that = this;
      if (that.district) {
        that.district.search("杨浦区", (status, result) => {
          // 外多边形坐标数组和内多边形坐标数组
          let outer = [
            new window.AMap.LngLat(-360, 90, true),
            new window.AMap.LngLat(-360, -90, true),
            new window.AMap.LngLat(360, -90, true),
            new window.AMap.LngLat(360, 90, true),
          ];
          let holes = result.districtList[0].boundaries;
          let pathArray = [outer];
          pathArray.push.apply(pathArray, holes);
          let polygon = new window.AMap.Polygon({
            pathL: pathArray,
            strokeColor: "#cbb486",
            strokeWeight: 2,
            fillColor: "#000000",
            fillOpacity: 0.5,
          });
          polygon.setPath(pathArray);
          that.map.add(polygon);
          if (!that.map.getLimitBounds()) {
            that.map.setLimitBounds(that.map.getBounds());
          }
        });
      }
    },
    // 初始化点标记
    handleMarkerShow() {
      const that = this;
      that.renderParkList.forEach((item) => {
        that.handleMarketAdd(item);
      });
    },
    // 清除地图
    handleMapClear() {
      if (this.map) {
        this.map.clearMap();
      }
    },
    // 地图焦点设置
    handleMapCenter(position) {
      if (this.map) {
        this.map.setCenter(position);
      }
    },
    // 地图缩放大小设置
    handleMapZoom(zoom) {
      if (this.map) {
        this.map.setZoom(zoom);
      }
    },
    // 地图显示在合适位置
    handleMapFitView() {
      if (this.map) {
        this.map.setFitView();
      }
    },
    // 添加点标记
    handleMarketAdd(item) {
      const { parkName, latitude, longitude, parkType, iconUrl } = item;
      const that = this;
      let content = parkName;
      if (parkType === "5") {
        new window.AMap.Circle({
          map: that.map,
          center: [longitude, latitude],
          radius: 1000,
          borderWeight: 1,
          strokeColor: "#FF33FF",
          strokeWeight: 1,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          strokeDasharray: [10, 10],
          fillColor: "#1791fc",
          zIndex: 50,
        });
      }
      let marker = new window.AMap.Marker({
        map: that.map,
        position: [longitude, latitude],
        icon: iconUrl,
        offset: new window.AMap.Pixel(-9.5, -25),
        label: {
          content: content,
          direction: "top",
        },
      });
      marker.on("click", () => that.handleParkDetailGet(item));
    },
    // 地图打点
    async handleSelfMarkAdd() {
      const that = this;
      const search = that.addrSearch.trim();
      if (!search) {
        that.$notify.warning({
          title: "提示",
          message: "请输入要打点的地址",
          duration: NOTIFY_STAY_TIME,
        });
        return;
      }
      const { data } = await gpsInfoGetByAddress({
        useType: that.hospitalType,
        address: search,
      });
      if (data.code === STATUS_CODE_OK) {
        const position = data.data.location.split(",");
        const icon = data.data.addressAvailable === "Y" ? mark_park : mark_gray;
        new window.AMap.Marker({
          map: that.map,
          position: position,
          icon: icon,
          offset: new window.AMap.Pixel(-9.5, -25),
          label: {
            content: search,
            direction: "top",
          },
        });
        that.map.panTo(position);
        that.$notify[data.data.addressAvailable === "Y" ? "success" : "error"]({
          title: "提示",
          message: data.data.SHOW_MSG,
          duration: NOTIFY_STAY_TIME,
        });
        that.addrSearch = "";
      }
    },
    // 显示搜索条件
    handleFilterShow() {
      this.filterFormTempList = jsonDeepClone(this.filterFormList);
      this.filterShow = true;
    },
    // filter条件确认事件
    async handleFilterSure() {
      const that = this;
      let { data } = await filterParkGet({
        filterIds: that.filterFormTempList.join(),
      });
      if (data.code === STATUS_CODE_OK) {
        that.selectedParkType = "";
        that.filterFormList = jsonDeepClone(that.filterFormTempList);
        that.parkList = data.data.parkInfo;
        that.handleHospitalTypeList();
        that.businessAreaList = data.data.businessAreaInfo;
        that.handleMapClear();
        that.handleYPQMap();
        if (that.filterFormTempList.length > 0) {
          that.handleBusinessAreaDraw();
        }
        that.handleMarkerShow();
        that.handleMapFitView();
        that.filterShow = false;
      }
    },
    // filter过滤条件重置
    handleFilterReset() {
      this.filterFormTempList = [];
    },
    // 右上角园区类型点击事件
    handleParkTypeClick(parkType = "1", hospitalType = "") {
      const that = this;
      that.selectedParkType = parkType;
      if (parkType === "101") {
        // 点击了办公楼宇类型
        that.handleMapClear();
        that.handleYPQMap();
        that.areaList.forEach((item) => {
          let polygon = new window.AMap.Polygon({
            path: JSON.parse(item.streetRegion),
            strokeColor: "#022535",
            strokeWeight: 0,
            strokeOpacity: 0,
            fillOpacity: 0.7,
            fillColor: item.color,
            zIndex: 1000,
          });
          polygon.on("click", () => {
            that.areaItem = item;
            that.handleAreaStreetShow();
          });
          let text = new window.AMap.Text({
            text: item.streetName,
            anchor: "center", // 设置文本标记锚点
            style: {
              "background-color": "transparent",
              "text-align": "center",
              "font-size": "24px",
              color: "#ffffff",
              border: "none",
            },
            position: item.centre.split(","),
            zIndex: 1001,
          });
          text.on("click", () => {
            that.areaItem = item;
            that.handleAreaStreetShow();
          });
          that.map.add(polygon);
          that.map.add(text);
        });
      } else if (parkType === "11") {
        // 点击了大学生创业田
        that.dialogDxsCztVisible = true;
        that.$nextTick(() => {
          const dia = that.$refs.diaDxsCtz;
          const { innerHeight, innerWidth } = window;
          const { offsetHeight, offsetWidth } = dia;
          dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
          dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
        });
      } else {
        that.hospitalType = hospitalType;
        that.$nextTick(() => {
          that.handleMapClear();
          that.handleYPQMap();
          if (
            that.filterFormTempList.length > 0 &&
            (!parkType || parkType === "1")
          ) {
            that.handleBusinessAreaDraw();
          }
          that.handleMarkerShow();
        });
      }
    },
    // 商圈绘制
    handleBusinessAreaDraw() {
      const that = this;
      that.businessAreaList.forEach((areaItem) => {
        let longitude = areaItem.centerCoordinate.split(",")[0];
        let latitude = areaItem.centerCoordinate.split(",")[1];
        new window.AMap.Circle({
          map: that.map,
          center: [longitude, latitude],
          radius: areaItem.coverageDistance,
          borderWeight: 1,
          strokeColor: "#FF33FF",
          strokeWeight: 1,
          strokeOpacity: 0.2,
          fillOpacity: 0.4,
          strokeDasharray: [10, 10],
          fillColor: "#1791fc",
          zIndex: 50,
        });
        new window.AMap.Text({
          map: that.map,
          text: areaItem.businessAreaName,
          anchor: "center", // 设置文本标记锚点
          style: {
            "font-size": "20px",
            "background-color": "transparent",
            border: "none",
            color: "#e4722d",
          },
          position: [longitude, latitude],
        });
      });
    },
    // 到这里去
    handleGoHere(type) {
      const that = this;
      that.mapNavType = type;
      const mapNavTypes = ["driving", "transfer", "riding", "walking"];
      mapNavTypes.forEach((item) => {
        that[item].clear();
      });
      if (that.dialogMapNavigationVisible) {
        that[type].search(YPQ_QZH_POSITION, [
          this.parkDetail.pcParkMasterInfoVO.longitude,
          this.parkDetail.pcParkMasterInfoVO.latitude,
        ]);
      } else {
        that.dialogMapNavigationVisible = true;
        that.$nextTick(() => {
          const dia = this.$refs.diaMapNavigation;
          const { innerHeight, innerWidth } = window;
          const { offsetHeight, offsetWidth } = dia;
          dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
          dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
          that[type].search(YPQ_QZH_POSITION, [
            this.parkDetail.pcParkMasterInfoVO.longitude,
            this.parkDetail.pcParkMasterInfoVO.latitude,
          ]);
        });
      }
    },
    // 信息复制
    async handleInfoCopy() {
      const that = this;
      try {
        await that.$copyText(
          that.parkDetail.pcParkMasterInfoVO.address +
            "," +
            that.parkDetail.pcParkMasterInfoVO.email
        );
        that.$alert("信息复制成功", {
          type: "success",
          title: "提示",
        });
      } catch (err) {
        that.$alert("信息复制失败", {
          type: "error",
          title: "提示",
        });
        console.log(err);
      }
    },
    // 显示五力图
    handleLeiDaTuShow() {
      const that = this;
      const dia = that.$refs.diaLeiDa;
      this.dialogLeiDaVisible = true;
      that.$nextTick(() => {
        const { innerHeight, innerWidth } = window;
        const { offsetHeight, offsetWidth } = dia;
        dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
        dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
        if (!that.leiDaChart) {
          that.leiDaChart = that.$echarts.init(that.$refs.leiDaChart);
        }
        that.leiDaChart.setOption({
          radar: {
            indicator: that.parkDetailExtra.parkRadarDataList.map((item) => {
              return {
                text: item.parkProType,
                max: 100,
                min: 0,
                color: "#666",
              };
            }),
            center: ["50%", "50%"],
            radius: "80%",
            name: {
              padding: 5,
              fontSize: 20,
              color: "#000",
              fontWeight: "bolder",
            },
          },
          series: [
            {
              type: "radar",
              lineStyle: {
                color: "#579bf8",
              },
              itemStyle: {
                color: "#579bf8",
              },
              areaStyle: {
                color: "#579bf8",
              },
              data: [
                {
                  value: that.parkDetailExtra.parkRadarDataList.map(
                    (item) => item.parkProContext
                  ),
                },
              ],
            },
          ],
        });
      });
    },
    // 获取表格合计内容
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index === 0) {
          sums[index] = "合计";
          return;
        }
        if (index === 3) {
          const values = data.map((item) => Number(item[column.property]));
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
          sums[index] = sums[index].toFixed(2);
          sums[index] += "㎡";
        } else {
          return;
        }
      });
      return sums;
    },
    // 表格行点击
    handleRoomRowClick(row) {
      if (row.imgPath) {
        this.handleImgView(row.imgPath);
      }
    },
    // 企业logo点击
    async handleQiYeLogo(item) {
      let { data } = await inchCompanyCardGet({
        inchImgId: item.imgId,
      });
      if (data.code === STATUS_CODE_OK) {
        const that = this;
        that.qiYeCompanyInfo = data.data;
        that.dialogQiYeInfoVisible = true;
        that.$nextTick(() => {
          const dia = that.$refs.diaQiYeInfo;
          const { innerHeight, innerWidth } = window;
          const { offsetHeight, offsetWidth } = dia;
          dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
          dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
        });
      }
    },
    // 查看当前区域楼宇
    async handleAreaStreetShow() {
      const that = this;
      that.dialogLouYuVisible = true;
      that.$nextTick(() => {
        const dia = that.$refs.diaLouYu;
        const { innerHeight, innerWidth } = window;
        const { offsetHeight, offsetWidth } = dia;
        dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
        dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
      });
    },
    // 大学生创业园详情
    handleGoCytDetail(parkId) {
      this.handleParkDetailGet({
        id: parkId,
        parkType: "1",
      });
    },
    // 大图查看
    async handleImgView(url) {
      const that = this;
      const { wHAspect } = await imgWidthHeightGet(url);
      const dia = that.$refs.diaImg;
      const img = that.$refs.viewImg;
      that.viewImgUrl = url;
      that.dialogImgVisible = true;
      that.$nextTick(() => {
        img.style.display = "block";
        if (wHAspect >= 1) {
          img.style.width = "1000px";
          img.style.height = "auto";
        } else {
          img.style.width = "auto";
          img.style.height = "1000px";
        }
        const { innerHeight, innerWidth } = window;
        const { offsetHeight, offsetWidth } = dia;
        dia.style.top = (innerHeight - offsetHeight) / 2 + "px";
        dia.style.left = (innerWidth - offsetWidth) / 2 + "px";
      });
    },
    // 合并表格单元格
    tableSpanMethod({ row, columnIndex }) {
      const that = this;
      if (columnIndex === 0) {
        for (
          let i = 0, len = that.parkDetail.pcParkBuildRentalInfoVO.length;
          i < len;
          i++
        ) {
          let item = that.parkDetail.pcParkBuildRentalInfoVO[i];
          if (item.baseBuildName === row.baseBuildName) {
            if (item.roomRentalInfoVOList.indexOf(row) === 0) {
              return {
                rowspan: that.tableCountMap[row.baseBuildName],
                colspan: 1,
              };
            } else {
              return {
                rowspan: 0,
                colspan: 0,
              };
            }
          }
        }
      }
    },
    // 销毁时间初始化
    handleTimeDestroy() {
      if (this.time) {
        window.clearInterval(this.time);
        this.time = null;
      }
    },
    // 销毁地图
    handleMapDestroy() {
      if (this.map) {
        this.map = null;
      }
    },
  },
  beforeDestroy() {
    this.handleTimeDestroy();
    this.handleMapDestroy();
  },
  components: {
    swiper,
    swiperSlide,
    vueSeamlessScroll,
  },
};
</script>
<style lang="scss">
$color_gold: #cbb486 !important;
.amap-logo,
.amap-copyright {
  display: none !important;
}
.amap-scale-text {
  color: #fff;
}
.amap_lib_placeSearch {
  border-top: none;
}
.amap-marker .amap-icon img {
  width: 19px !important;
  height: 25px !important;
}
.amap-marker-label {
  border-radius: 4px !important;
  border: 1px solid $color_gold;
  color: $color_gold;
  padding: 5px 8px !important;
}
.amap-touch-toolbar .amap-zoomcontrol {
  background-color: $color_gold;
  border: 1px solid $color_gold;
  box-shadow: 1px 1px 10px 0 $color_gold;
}
.amap-zoom-touch-plus,
.amap-zoom-touch-minus {
  background-color: $color_gold;
}
.amap-zoom-touch-plus > div,
.amap-zoom-touch-minus > div {
  color: #fff !important;
}
.amap-scale-text {
  color: $color_gold;
}
.amap-scale-middle,
.amap-scale-edgeleft,
.amap-scale-edgeright {
  background-color: $color_gold;
}
.nav-map-panel {
  .amap-call {
    display: none !important;
  }
}
.amap-pl-pc {
  .poi-more {
    display: none !important;
  }
}
.el-table__empty-text {
  width: 100% !important;
}
.swiper-pagination-bullet-active {
  background: $color_gold;
}
.el-button--primary,
.el-button--primary:hover,
.el-button--primary:focus {
  background-color: #fff;
  color: $color_gold;
  border-color: $color_gold;
}
.tag-link {
  a {
    color: #5993d3 !important;
    border-bottom: 1px solid #5993d3 !important;
  }
}
.el-message-box__wrapper {
  z-index: 10002 !important;
}
.v-modal {
  z-index: 10001 !important;
}
</style>
<style lang="scss" scoped>
@keyframes shaking {
  0% {
    transform: translateY(-5px);
  }
  50% {
    transform: translateY(5px);
  }
  100% {
    transform: translateY(-5px);
  }
}
$color_gold: #cbb486;
.hospital-type-wrap {
  .no-data {
    color: #999;
    font-size: 16px;
  }
  .hospital-type {
    padding: 5px 0;
    font-size: 16px;
    border-bottom: 1px dashed #666;
    cursor: pointer;
    &.active {
      color: #dbb91d;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}
.map-mark-search-wrap {
  .search-title {
    margin-bottom: 8px;
    color: #999;
  }
  .search-input-wrap {
    display: flex;
    align-items: center;
    .search-input {
      margin-right: 10px;
    }
  }
}
.page-wrap {
  position: relative;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  .map-wrap {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: #5e6b70 !important;
  }
  .weather-time-wrap {
    position: absolute;
    top: 10px;
    right: 20px;
    z-index: 666;
    color: #ffb545;
    font-size: 18px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    .weather {
      margin: 10px 11px 0 0;
      display: flex;
      align-items: center;
      color: #fff;
      .text {
        margin: 0 10px;
      }
    }
    .time {
      margin: 10px 11px 0 0;
      color: #fff;
      font-size: 20px;
    }
    .park-type-wrap {
      margin: 10px 11px 0 0;
      .item {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;
        cursor: pointer;
        .round {
          width: 19px;
          height: 25px;
          margin-left: 10px;
        }
        .icon {
          width: 19px;
          height: 25px;
          margin-left: 10px;
        }
        &.map-mark {
          color: #fff;
          margin-top: 15px;
          .round {
            width: 22px;
            height: 22px;
            background: url(../assets/images/map-mark.png) center center;
            background-size: 100% 100%;
          }
        }
      }
    }
  }
  .btn-filter {
    position: absolute;
    top: 50%;
    left: 0;
    margin: -52px 0 0 0;
    padding: 10px;
    height: 104px;
    width: 36px;
    border-left: none;
    text-align: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    background-image: linear-gradient(to bottom, #e4c995, #b9916a);
    color: #fff;
    cursor: pointer;
  }
  .filter-wrap {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 666;
    display: flex;
    align-items: center;
    .mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba(0, 0, 0, 0.5);
      z-index: 667;
    }
    .filter-content {
      position: relative;
      z-index: 668;
      padding: 10px 45px 10px 15px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 4px;
      background-color: #fff;
      box-shadow: 1px 1px 5px 0 $color_gold;
      .el-icon-close {
        position: absolute;
        top: 10px;
        right: 10px;
        color: $color_gold;
        font-size: 30px;
        cursor: pointer;
      }
      .item {
        display: flex;
        align-items: center;
        padding: 10px 0;
        .title {
          font-weight: bolder;
          color: $color_gold;
          font-size: 16px;
        }
        .condition {
          margin-left: 20px;
          ::v-deep .el-checkbox {
            color: #333;
          }
          ::v-deep .el-checkbox__input.is-checked + .el-checkbox__label {
            color: $color_gold;
          }
          ::v-deep .el-checkbox__input.is-focus {
            .el-checkbox__inner {
              background-color: #fff;
              border-color: $color_gold;
            }
          }
          ::v-deep .el-checkbox__input.is-checked {
            .el-checkbox__inner {
              background-color: $color_gold !important;
              border-color: $color_gold;
            }
          }
        }
      }
      .btn-wrap {
        display: flex;
        justify-content: center;
        padding: 20px 0 10px 0;
        .btn {
          margin: 0 20px;
          padding: 6px 20px;
          border-radius: 4px;
          cursor: pointer;
          &.btn-sure {
            background-image: linear-gradient(to right, #e4c995, #b9916a);
            color: #fff;
          }
          &.btn-cancel {
            color: $color_gold;
            border: 1px solid $color_gold;
          }
        }
      }
    }
  }
  .m-dialog {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
    .dia-content-wrap {
      padding: 15px;
      width: 1050px;
      background-color: #fff;
      position: absolute;
      border-radius: 4px;
      .main-wrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        .text-wrap {
          flex: 1;
          .logo {
            display: block;
            height: 75px;
            margin-bottom: 5px;
            line-height: 75px;
            font-size: 18px;
          }
          .logo-text {
            display: block;
            height: 75px;
            margin-bottom: 5px;
            line-height: 75px;
            font-size: 24px;
            font-weight: bolder;
          }
          .item-wrap {
            display: flex;
            align-items: center;
            padding-right: 10px;
          }
          .item {
            display: flex;
            flex: 1;
            align-items: center;
            padding: 5px 0;
            font-size: 16px;
            .fa {
              color: $color_gold;
              margin-right: 10px;
            }
            .go-here {
              margin-left: 10px;
              font-size: 14px;
              cursor: pointer;
              color: $color_gold;
              display: flex;
              align-items: center;
              .fa {
                margin-left: 3px;
                color: $color_gold;
                font-size: 20px;
              }
            }
          }
        }
        .gz-wrap {
          width: 135px;
          margin: 10px 15px 0 0;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .gz-code {
            display: block;
            width: 100%;
          }
          .text {
            font-size: 12px;
            margin-top: 5px;
          }
        }
        .code-wrap {
          position: relative;
          width: 125px;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .code {
            display: block;
            width: 125px;
            height: 150px;
            background: url(../assets/images/code.png) no-repeat center center;
            background-size: 100% 100%;
          }
          .canvas {
            position: absolute;
            top: 40px;
            left: 10px;
          }
          .text {
            font-size: 12px;
            margin-top: 5px;
          }
        }
      }
    }
    .main-img-wrap {
      display: flex;
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 0;
        .title {
          margin: 5px 0;
          color: $color_gold;
          font-size: 16px;
          font-weight: bolder;
          border-left: 5px solid $color_gold;
          padding-left: 6px;
          display: flex;
          align-items: center;
          .leida-wrap {
            cursor: pointer;
            display: flex;
            align-items: center;
            padding-left: 20px;
            color: $color_gold;
            font-weight: normal;
            .img-point {
              height: 20px;
              margin-right: 5px;
            }
            .img-leida {
              height: 20px;
              margin-left: 5px;
            }
          }
        }
        .img {
          height: 260px;
          background-size: contain !important;
        }
        .img-wrap {
          height: 260px;
          .img-cly {
            display: inline-block;
            height: 120px;
            width: 156px;
            margin: 5px;
            background-size: contain !important;
          }
        }
        &.dzdt {
          margin-right: 10px;
        }
        &.cyl {
          margin-left: 10px;
        }
      }
    }
    .main-lunbo-fy-wrap {
      display: flex;
      .item {
        flex: 1;
        display: flex;
        flex-direction: column;
        width: 0;
        .title {
          margin: 5px 0;
          color: $color_gold;
          font-size: 16px;
          font-weight: bolder;
          border-left: 5px solid $color_gold;
          padding-left: 6px;
        }
        .carousel {
          .img {
            background-size: contain !important;
            height: 300px;
          }
        }
        &.kzfy {
          margin-right: 10px;
          .empty-text {
            line-height: 1.8;
            color: #777;
            font-size: 22px;
            padding: 0 25px;
          }
        }
        &.ypyl {
          margin-left: 10px;
        }
      }
    }
    .dia-filter-wrap {
      font-size: 16px;
      .row {
        display: flex;
        align-items: center;
        .item {
          flex: 1;
          display: flex;
          align-items: center;
          padding: 5px 0;
        }
      }
    }
    .label-wrap {
      .title {
        margin: 10px 0 5px 0;
        color: $color_gold;
        font-size: 16px;
        font-weight: bolder;
        border-left: 5px solid $color_gold;
        padding-left: 6px;
        display: flex;
        align-items: center;
      }
      .label {
        margin-top: 10px;
        display: inline-block;
        background-color: #fc7a43;
        color: #fff;
        font-size: 18px;
        letter-spacing: 2px;
        margin-right: 15px;
        padding: 0.2em 0.5em;
        border-radius: 999px;
        &.tag-link {
          background-color: transparent;
          border: 1px solid #5993d3;
        }
      }
    }
    .btn-wrap {
      padding-top: 20px;
      display: flex;
      justify-content: flex-end;
    }
    &.bmd {
      .dia-content-wrap {
        width: 1200px;
        // left: 560px;
        .title {
          font-size: 16px;
          padding: 0 0 10px 0;
        }
        .build-title {
          color: #666;
          font-size: 16px;
          padding: 20px 0px 10px 15px;
          position: relative;
          &::after {
            display: block;
            content: "";
            width: 5px;
            height: 20px;
            background: #409eff;
            position: absolute;
            left: 2px;
            top: 21px;
          }
        }
        .con-wrap {
          display: flex;
          .item {
            flex: 1;
            width: 0;
            display: flex;
            align-items: center;
            padding: 5px;
            .text {
              display: flex;
              align-items: center;
              flex-wrap: wrap;
              .go-here {
                margin-left: 10px;
                font-size: 14px;
                cursor: pointer;
                color: $color_gold;
                display: flex;
                align-items: center;
                .fa {
                  margin-left: 3px;
                  color: $color_gold;
                  font-size: 20px;
                }
              }
              .tag {
                background-color: #fc7a43;
                color: #fff;
                padding: 0.2em 0.5em;
                border-radius: 9999px;
                margin-right: 10px;
                &:last-child {
                  margin-right: 0;
                }
              }
            }
            .fa-map-marker,
            .fa-phone,
            .fa-tag {
              margin-right: 5px;
              color: $color_gold;
            }
            .fa-map-marker {
              font-size: 22px;
            }
          }
        }
        .flex-wrap {
          flex-wrap: wrap;
          padding: 0 10px;
          .m-col {
            display: flex;
            align-items: flex-start;
            padding: 5px 5px !important;
            .p-info-title {
              display: flex;
              align-items: center;
              .fa {
                margin-right: 5px;
                color: $color_gold;
                font-size: 14px;
              }
            }
            .p-info-con {
              flex: 1;
              white-space: pre-wrap;
            }
          }
        }
      }
    }
    &.map-navigation {
      .dia-content-wrap {
        width: 1200px;
        .map-nav-wrap {
          display: flex;
          align-items: center;
          justify-content: center;
          .map-nav {
            height: 800px;
            flex: 1;
          }
          .map-nav-result-wrap {
            height: 800px;
            width: 400px;
            margin-left: 20px;
            .nav-type-wrap {
              background-color: #fff;
              display: flex;
              margin-bottom: 10px;
              .type {
                flex: 1;
                padding: 0.2em 0.5em;
                text-align: center;
                cursor: pointer;
                font-size: 18px;
                letter-spacing: 2px;
                border-bottom: 2px solid transparent;
                &.active {
                  color: $color_gold;
                  border-bottom: 2px solid $color_gold;
                }
              }
            }
          }
        }
      }
    }
    &.img-view {
      z-index: 10001;
      .dia-content-wrap {
        width: auto;
      }
    }
    &.qi-ye-info {
      z-index: 10001;
      .dia-content-wrap {
        width: auto;
        .info-wrap {
          .name {
            font-size: 18px;
            font-weight: bolder;
            margin-bottom: 10px;
          }
          .info {
            display: flex;
            .logo-img {
              margin-right: 10px;
              width: 90px;
              height: 90px;
              background-size: contain !important;
              border: 1px solid #ddd;
              border-radius: 4px;
            }
            .des {
              width: 400px;
              color: #999;
              text-indent: 2em;
              line-height: 1.5;
            }
          }
        }
      }
    }
    &.lei-da {
      .dia-content-wrap {
        width: auto;
        .title {
          text-align: center;
          padding: 10px 0;
          font-size: 24px;
          font-weight: bolder;
          letter-spacing: 3px;
        }
        .chart {
          width: 800px;
          height: 600px;
        }
      }
    }
    &.lou-yu {
      .dia-content-wrap {
        width: 1200px;
        .title {
          text-align: center;
          padding: 10px 0;
          font-size: 24px;
          font-weight: bolder;
          letter-spacing: 3px;
        }
      }
    }
    &.dxs-czt {
      .dia-content-wrap {
        font-size: 14px;
        width: 375px;
        background-color: #fff8e7;
        padding: 0;
        .btn-wrap {
          padding: 0 10px 20px 0;
        }
        .ding {
          display: block;
          width: 100%;
        }
        .title1 {
          width: 330px;
          display: block;
          margin: 15px auto 0 auto;
        }
        .title2 {
          position: relative;
          z-index: 2;
          width: 352px;
          height: 42px;
          background: url(../assets/images/czt-biaoti2.png) no-repeat center
            center;
          background-size: 100% 100%;
          margin: 15px auto 0 auto;
          color: #fff;
          font-style: italic;
          .row1 {
            padding: 9px 0 0 86px;
          }
          .row2 {
            padding-left: 196px;
            position: relative;
            top: -11px;
          }
        }
        .con-wrap {
          padding: 20px 12px 0 12px;
          position: relative;
          z-index: 1;
          top: -28px;
          .con {
            border: 2px dashed #ccb886;
            border-radius: 8px;
            padding: 20px 10px;
            .text {
              text-indent: 2em;
              line-height: 1.6;
            }
            .tian-wrap {
              width: 317px;
              height: 185px;
              margin: 5px auto 0 auto;
              background: url(../assets/images/czt-bg.png) no-repeat center
                center;
              background-size: 100% 100%;
              position: relative;
              .tian {
                position: absolute;
                width: 136px;
                height: 94px;
                cursor: pointer;
                font-size: 12px;
                line-height: 1;
                &.tian1 {
                  left: 20px;
                  bottom: 35px;
                  .text-wrap {
                    text-align: center;
                    color: #270300;
                    position: absolute;
                    top: 28px;
                    left: 10px;
                    transform: rotate(-8deg);
                  }
                }
                &.tian2 {
                  left: 163px;
                  bottom: 15px;
                  .text-wrap {
                    text-align: center;
                    color: #270300;
                    position: absolute;
                    top: 28px;
                    left: 4px;
                    transform: rotate(-8deg);
                  }
                }
                .shu {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 80px;
                  display: block;
                }
                .cw {
                  position: absolute;
                  top: 0;
                  right: 0;
                  width: 80px;
                  display: block;
                  animation: shaking 2s infinite;
                }
              }
            }
          }
        }
      }
    }
  }
  .street-list-wrap {
    position: fixed;
    right: 10px;
    bottom: 10px;
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 4px;
    width: 1260px;
    .title {
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 18px;
      padding: 10px 0 0 0;
      letter-spacing: 8px;
    }
    .item-title-wrap {
      display: flex;
      .item-title {
        flex: 1;
        text-align: center;
        padding: 10px 0;
        color: #fff;
        font-size: 16px;
      }
    }
    .street-scroll {
      overflow: hidden;
      height: 60px;
      .scroll-list {
        .scroll-item {
          display: flex;
          padding: 5px 0;
          .item {
            flex: 1;
            color: #fff;
            text-align: center;
          }
        }
      }
    }
  }
}
</style>
